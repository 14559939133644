@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&family=Modern+Antiqua&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

nav {
  font-weight: 700;
}

.crop {
  width: 800px;
  height: 800px;
  overflow: hidden;
}

.crop img {
  width: 80%;
  height: auto;
}

.backgimg {
  background-image: url('./pictures/HP2-min.jpg');
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}

.md-img {
  width: 400px;
}

.card:hover {
  /* border-radius: 20px; */
  outline: 2px solid #215482;
  cursor: pointer;
}

.backgimg2 {
  background-image: url('./pictures/HP9-min.jpg');
  width: 100%;
  height: 800px;
  background-position: center;
  background-size: cover;
}
.home-btn:hover {
  background: white;
  color: #215482;
  transition: 0.3s;
}
.bottom-img {
  background-image: url('./pictures/HP10-min.jpg');
  height: 500px;
  background-position: center;
  background-size: cover;
}

/* nav start */
nav span {
  position: relative;
  display: block;
  cursor: pointer;
}

nav span:before,
nav span:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  top: 50%;
  margin-top: -0.5px;
  background: #215482;
}

nav span:before {
  left: -2.5px;
}
nav span:after {
  right: 2.5px;
  background: #215482;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

nav span:hover:before {
  background: #215482;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

nav span:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

/*nav end*/

.container2 {
  position: relative;
  /* width: 500px; */
}

.image {
  opacity: 1;
  display: block;
  width: 900px;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container2:hover .image {
  opacity: 0.3;
}

.container2:hover .middle {
  opacity: 1;
}

.text {
  /* background-color: #2c3333; */
  color: white;
  font-size: 25px;
  /* padding: 16px 32px; */
  border-radius: 10px;
}

.slant {
  -ms-transform: rotate(5deg); /* IE 9 */
  transform: rotate(5deg);
}
.slant2 {
  -ms-transform: rotate(-5deg); /* IE 9 */
  transform: rotate(-5deg);
}

.slant3 {
  -ms-transform: rotate(3deg); /* IE 9 */
  transform: rotate(3deg);
}
.slant4 {
  -ms-transform: rotate(-3deg); /* IE 9 */
  transform: rotate(-3deg);
}

.divider {
  position: relative;
  margin-top: 90px;
  height: 1px;
}

.div-transparent:before {
  content: '';
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(48, 49, 51),
    transparent
  );
}

.mod {
  font-family: 'Modern Antiqua', cursive;
}

.bg-main {
  background-image: url('./pictures/HP1-min.jpg');
  background-size: cover;
  background-position: center;
}

.bg-ground {
  background-image: url('./pictures/GF1-min.jpg');
  background-size: cover;
  background-position: center;
}
.bg-reviews {
  background-image: url('./pictures/RP1-min.jpg');
  background-size: cover;
  background-position: center;
}
.bg-contact {
  background-image: url('./pictures/back-dark-min.jpeg');
  background-size: cover;
  background-position: center;
}
.bg-penthouse {
  background-image: url('./pictures/ph-main-min.jpeg');
  background-size: cover;
  background-position: center;
}
.bg-main-floor {
  background-image: url('./pictures/MP1-min.jpg');
  background-size: cover;
  background-position: center;
}

.back-ground-floor {
  background-image: url('./pictures/gf-alternate-min.jpeg');
  background-size: cover;
}

.back-main-floor {
  background-image: url('./pictures/MP19-min.jpg');
  background-size: cover;
}

.back-penthouse {
  background-image: url('./pictures/ph-18-new-min.jpeg');
  background-size: cover;
}

input::placeholder {
  color: black;
  opacity: 1;
}

textarea::placeholder {
  color: black;
  opacity: 1;
}

.large-navigation {
  display: block;
}

.small-navigation {
  display: none;
}

@media screen and (max-width: 1024px) {
  .small-navigation {
    display: block;
  }
  .large-navigation {
    display: none;
  }
}
